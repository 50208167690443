import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import Department from "../models/Department";
import { fetchGet } from "wcz-layout";
import { departmentsUrl } from "../utils/BaseUrl";
import { useGetProjects } from "./ProjectSettingsQueries";

const queryKey = "Departments";

export function useGetDepartments<TQueryFnData = Department[], TError = string, TData = TQueryFnData>(options?: Omit<UseQueryOptions<TQueryFnData, TError, TData>, "queryKey" | "queryFn" | "initialData">) {
    const departmentQuery = useQuery([queryKey], ({ signal }) => fetchGet(departmentsUrl, signal), options);
    const { data: projectSettings } = useGetProjects();
    if (!departmentQuery.data || !projectSettings){ return { ...departmentQuery, data: undefined }; }
    
    const departmentDic: {[key: string]: string} = {};
    (departmentQuery.data as Department[]).forEach(department => {
        departmentDic[department.departmentId] = department.description;
    });    

    const departmentIdsFromProjectSettings = Array.from(new Set(projectSettings.map(ps => ps.departmentId))).filter(id => id !== "CWZXXX");

    const data = departmentIdsFromProjectSettings
        .map(id => ({ departmentId: id, description: departmentDic[id] }))
        .sort((a, b) => a.departmentId.localeCompare(b.departmentId, undefined, { numeric: true, sensitivity: "base" }));
    return { ...departmentQuery, data: [...data, { departmentId: "CWZXXX", description: "Company Support" } ] }
}

export function useGetPeoplesoftDepartments<TQueryFnData = Department[], TError = string, TData = TQueryFnData>(options?: Omit<UseQueryOptions<TQueryFnData, TError, TData>, "queryKey" | "queryFn" | "initialData">) {
    const query = useQuery([queryKey], ({ signal }) => fetchGet(departmentsUrl, signal), options);
    if (!query.data){ return query; }
    const data = (query.data as Department[]).sort((a, b) => a.departmentId.localeCompare(b.departmentId, undefined, { numeric: true, sensitivity: "base" }));
    return { ...query, data: [...data, { departmentId: "CWZXXX", description: "Company Support" } ] }
}