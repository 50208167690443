import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, MenuItem, Select, TextField, Tooltip, Typography } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { cloneDeep } from "lodash";
import moment from "moment";
import { SetStateAction, useContext, useEffect, useState } from "react";
import { fetchGet, fetchPut, LayoutContext } from "wcz-layout";
import ApplicationData from "../../models/ApplicationData";
import { ApprovalStatus } from "../../models/ApprovalStatus";
import Break from "../../models/Break";
import DailyWorkloadApplication from "../../models/DailyWorkloadApplication";
import Department from "../../models/Department";
import Head from "../../models/Head";
import HeadcountSummaryModel from "../../models/HeadcountSummaryModel";
import { apiUrl } from "../../utils/BaseUrl";
import { ProjectSettingsContext } from "../../contexts/ProjectSettingsContext";
import ProjectLine from "../../models/ProjectLine";
import LineStation from "../../models/LineStation";
import Project from "../../models/Project";
import { useGetDepartments } from "../../queries/DepartmentQueries";

function calculateWorkedHours(start: string, end: string, breaks: Break[]): string {
    if (!start || !end) return "";

    let workStart = moment(start, "H:mm");
    let workEnd = moment(end, "H:mm");

    let workDiff = workEnd.diff(workStart);
    let breakDiff = 0;

    //let breaks: Break[] = breakSchedule.filter(b => b.departmentId === employee.departmentId && b.project === employee.project && b.line === employee.line);    
    for (let varBreak of breaks) {
        let breakStart = moment(varBreak.start, "H:mm");
        let breakEnd = moment(varBreak.end, "H:mm");

        // Work start and end time are both in the middle of the break
        if (workStart.isSameOrAfter(breakStart) && workEnd.isSameOrBefore(breakEnd)) {
            workDiff = 0;
            break;
        }

        // #1: The whole break is in the middle of the working time
        if (workStart.isBefore(breakStart) && workEnd.isAfter(breakEnd)) {
            breakDiff += breakEnd.diff(breakStart);
        }
        // #2: The work start is in the middle of the break or is the same as the break start time
        else if (workStart.isBetween(breakStart, breakEnd) || workStart.isSame(breakStart)) {
            breakDiff += breakEnd.diff(workStart);
        }
        // #3: The work end is in the middle of the break or is the same as the break end time
        else if (workEnd.isBetween(breakStart, breakEnd) || workEnd.isSame(breakEnd)) {
            breakDiff += workEnd.diff(breakStart);
        }
    }

    let workAndBreakDiffsInHours = moment.duration(workDiff - breakDiff);
    return moment.utc(workAndBreakDiffsInHours.asMilliseconds()).format("H:mm");
}

function calculateOvertime(employee: HeadcountSummaryModel, appDataItem: ApplicationData, dayToEdit: string, breaks: Break[]): string {
    if (!employee.workshiftEnd || employee.workshiftEnd === "00:00") return "";
    if (!appDataItem.newStart || !appDataItem.newEnd) return "";

    const isWeekend: boolean = moment(dayToEdit).day() === 0 || moment(dayToEdit).day() === 6 ? true : false;
    const isHoliday: boolean = false; //TODO: call service to get if is bank holiday
    if (isWeekend || isHoliday) return appDataItem.newWorked ?? "";

    let workStart = moment(appDataItem.newStart, "H:mm");
    let workEnd = moment(appDataItem.newEnd, "H:mm");
    let workshiftEnd = moment(employee.workshiftEnd, "H:mm");
    let overtime = "";
    if (workEnd.isAfter(workshiftEnd)) {
        if (workStart.isAfter(workshiftEnd)) {
            overtime = moment(workEnd).subtract(moment.duration(appDataItem.newStart)).format("H:mm");
        }
        else {
            overtime = moment(workEnd).subtract(moment.duration(employee.workshiftEnd)).format("H:mm");
        }

        let breaktimeDuration = 0;
        let overtimeBreaks: Break[] = breaks.filter(b => b.type === "Overtime1" || b.type === "Overtime2");
        overtimeBreaks.forEach((element) => {
            let breakStart = moment(element.start, "H:mm");
            let breakEnd = moment(element.end, "H:mm");
            if (breakStart.isAfter(workStart) && breakEnd.isSameOrBefore(workEnd)) {
                breaktimeDuration += element.durationInMinutes;
            }
        });

        if (breaktimeDuration) {
            overtime = moment(overtime, "H:mm").subtract(moment.duration(breaktimeDuration, "minutes")).format("H:mm");
        }
    }

    return overtime;
}

function calculateBreaktime(start: string, end: string, breaks: Break[]): string {
    if (!start || !end) return "";

    let workStart = moment(start, "H:mm");
    let workEnd = moment(end, "H:mm");

    //let breaks: Break[] = breakSchedule.filter(b => b.departmentId === employee.departmentId && b.project === employee.project && b.line === employee.line);

    let breakDiff = 0;
    for (let varBreak of breaks) {
        let breakStart = moment(varBreak.start, "H:mm");
        let breakEnd = moment(varBreak.end, "H:mm");

        // Work start and end time are both in the middle of the break
        if (workStart.isSameOrAfter(breakStart) && workEnd.isSameOrBefore(breakEnd)) {
            breakDiff = workEnd.diff(workStart);;
            break;
        }

        // #1: The whole break is in the middle of the working time
        if (workStart.isBefore(breakStart) && workEnd.isAfter(breakEnd)) {
            breakDiff += breakEnd.diff(breakStart);
        }
        // #2: The work start is in the middle of the break or is the same as the break start time
        else if (workStart.isBetween(breakStart, breakEnd) || workStart.isSame(breakStart)) {
            breakDiff += breakEnd.diff(workStart);
        }
        // #3: The work end is in the middle of the break or is the same as the break end time
        else if (workEnd.isBetween(breakStart, breakEnd) || workEnd.isSame(breakEnd)) {
            breakDiff += workEnd.diff(breakStart);
        }
    }

    if (breakDiff === 0) {
        return "";
    }

    let breakDiffInHours = moment.duration(breakDiff);
    return moment.utc(breakDiffInHours.asMilliseconds()).format("H:mm");
}

interface EditDailyWorkloadApplicationDialogProps {
    dailyWorkloadApplicationId: string,
    setDailyWorkloadApplicationId: (id: string) => void,
    setDailyWorkloadApplications: (applicationArray: SetStateAction<DailyWorkloadApplication[]>) => void,
    employee: HeadcountSummaryModel,
    headsOfEmployee: Head[],
    breaks: Break[],
}

export default function EditDailyWorkloadApplicationDialog(props: EditDailyWorkloadApplicationDialogProps) {
    const { user } = useContext(LayoutContext);
    const { projectList } = useContext(ProjectSettingsContext);

    const { data: departmentList = [] } = useGetDepartments();

    const [dailyWorkloadApplication, setDailyWorkloadApplication] = useState<DailyWorkloadApplication>({} as DailyWorkloadApplication);
    const [applicationData, setApplicationData] = useState<ApplicationData[]>([] as ApplicationData[]);
    const { breaks } = props;
    const iAmLeader: boolean = props.headsOfEmployee.some(head => head.employeeId === user.id);

    // Dialog: Apply for changes - validation variables
    const [hasNoChangeError, setHasNoChangeError] = useState(false);
    const [fieldReasonHasError, setFieldReasonHasError] = useState(false);
    const [fieldStartOrEndIsDuringBreaktimeError, setFieldStartOrEndIsDuringBreaktimeError] = useState(false);

    useEffect(() => {
        if (hasNoChangeError) {
            setTimeout(() => setHasNoChangeError(false), 5000);
        }
    }, [hasNoChangeError])

    useQuery<DailyWorkloadApplication>(["dailyWorkloadApp-edit", props.dailyWorkloadApplicationId], ({ signal }) => fetchGet(`${apiUrl}/v1/dailyworkloadapplication/${props.dailyWorkloadApplicationId}`, signal), {
        enabled: !!props.dailyWorkloadApplicationId,
        onSuccess: data => {
            setDailyWorkloadApplication(data);
            setApplicationData(cloneDeep(data.applicationData!))
        }
    });

    const cleanValidation = () => {
        setFieldReasonHasError(false);
        setFieldStartOrEndIsDuringBreaktimeError(false);
    }  

    const handleClickCancelDialog = () => {
        cleanValidation();
        props.setDailyWorkloadApplicationId("");
    };

    const nothingChanged = () => {
        for (var i = 0; i < applicationData.length; i++) {
            if (applicationData[i].newWorkloadDepartmentId !== dailyWorkloadApplication.applicationData![i].newWorkloadDepartmentId
                || applicationData[i].newProject !== dailyWorkloadApplication.applicationData![i].newProject
                || applicationData[i].newLine !== dailyWorkloadApplication.applicationData![i].newLine
                || applicationData[i].newStation !== dailyWorkloadApplication.applicationData![i].newStation
                || applicationData[i].newStart !== dailyWorkloadApplication.applicationData![i].newStart
                || applicationData[i].newEnd !== dailyWorkloadApplication.applicationData![i].newEnd) {
                return false;
            }
        }

        return true;
    };

    const canBeSaved = () => {
        let result = true;

        if (!dailyWorkloadApplication.reason) {
            setFieldReasonHasError(true);
            return false;
        }

        if (nothingChanged()) {
            setHasNoChangeError(true);
            return false;
        }

        applicationData.forEach((appDataItem, idx) => {
            if (!/^([0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(appDataItem.newStart!))
                result = false;
            if (moment(appDataItem.newStart, "H:mm").isSameOrAfter(moment(appDataItem.newEnd, "H:mm")))
                result = false;
            if (idx > 0 && moment(appDataItem.newStart, "H:mm").isBefore(moment(applicationData[idx - 1].newEnd, "H:mm")))
                result = false;
            if (!/^([0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(appDataItem.newEnd!))
                result = false;
            if (idx <= (applicationData.length - 2) && moment(appDataItem.newEnd, "H:mm").isAfter(moment(applicationData[idx + 1].newStart, "H:mm")))
                result = false;            
        })

        return result;
    };

    const { mutate: putUpdatedApplication } = useMutation((request: DailyWorkloadApplication) => fetchPut(`${apiUrl}/v1/DailyWorkloadApplication/${request.id}`, request), {
        onSuccess: () => props.setDailyWorkloadApplications(prevState => [...prevState.filter(app => app.id !== dailyWorkloadApplication.id), dailyWorkloadApplication])
    });

    const handleClickApply = () => {
        if (!canBeSaved()) return;

        let employeeLeader = props.headsOfEmployee.find(h => h.level === "Leader");
        if (!employeeLeader) return; //TODO: tady by to melo vypsat chybovou hlasku, ze nebylo nalezeno, kdo je Leader zamestnance

        var updatedApplication = dailyWorkloadApplication;
        updatedApplication.applicationData = applicationData;
        updatedApplication.status = iAmLeader ? ApprovalStatus.Approved : ApprovalStatus.WaitingForApproval;
        updatedApplication.approverEmployeeId = employeeLeader!.employeeId;
        updatedApplication.approverName = employeeLeader!.name;
        updatedApplication.approverComment = "";

        setDailyWorkloadApplication(updatedApplication);
        putUpdatedApplication(updatedApplication);

        cleanValidation();
        props.setDailyWorkloadApplicationId("");
    };

    return (
        <Dialog open={!!props.dailyWorkloadApplicationId} maxWidth={"xl"}>
            <DialogTitle>Edit Daily Workload Application for: {moment(dailyWorkloadApplication.day).format("MM/DD dddd")}</DialogTitle>

            <DialogContent>
                {
                    dailyWorkloadApplication.status === ApprovalStatus.Rejected &&
                    <Grid container sx={{ mb: '25px' }}>
                        <Grid item xs={6} lg={2}>
                            <Typography component="span" variant="subtitle1" sx={{ fontWeight: 600 }}>Rejection reason:</Typography>
                        </Grid>
                        <Grid item xs={12} lg={8}>
                            <TextField
                                margin="dense"
                                sx={{ mt: '0px', mb: '4px', input: { color: 'black', WebkitTextFillColor: 'inherit!important' } }}
                                fullWidth
                                id="comment"
                                type="text"
                                variant="outlined"
                                size="small"
                                value={dailyWorkloadApplication?.approverComment ?? ""}
                                disabled                                
                            />
                        </Grid>
                    </Grid>
                }
                <Grid container spacing={2}>
                    <Grid item><Typography component="span" variant="subtitle1" sx={{ fontWeight: 600 }}>Breaks:</Typography></Grid>
                    <Grid item>{breaks[0]?.start} - {breaks[0]?.end}</Grid>
                    <Grid item>{breaks[1]?.start} - {breaks[1]?.end}</Grid>
                    <Grid item>{breaks[2]?.start} - {breaks[2]?.end}</Grid>
                    <Grid item>{breaks[3]?.start} - {breaks[3]?.end}</Grid>
                </Grid>
                <Grid container sx={{ mb: "10px" }}>
                    <Grid item>
                        <Typography component="span" variant="subtitle1" sx={{ color: fieldStartOrEndIsDuringBreaktimeError ? 'red' : 'lightgray', fontStyle: 'italic' }}>
                            Remark: The start and end time must not conflict with the break times while editing.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ borderBottom: "3px solid #005363", mb: "10px" }}>
                    <Grid item sx={{ minWidth: "170px" }}><Typography component="span" variant="subtitle1" sx={{ fontWeight: 600 }}>Department Code</Typography></Grid>
                    <Grid item sx={{ minWidth: "200px" }}><Typography component="span" variant="subtitle1" sx={{ fontWeight: 600 }}>Project</Typography></Grid>
                    <Grid item sx={{ minWidth: "60px" }}><Typography component="span" variant="subtitle1" sx={{ fontWeight: 600 }}>Line</Typography></Grid>
                    <Grid item sx={{ minWidth: "200px" }}><Typography component="span" variant="subtitle1" sx={{ fontWeight: 600 }}>Station</Typography></Grid>
                    <Grid item sx={{ minWidth: "100px" }}><Typography component="span" variant="subtitle1" sx={{ fontWeight: 600 }}>Start</Typography></Grid>
                    <Grid item sx={{ minWidth: "100px" }}><Typography component="span" variant="subtitle1" sx={{ fontWeight: 600 }}>End</Typography></Grid>
                    <Grid item sx={{ minWidth: "100px" }}><Typography component="span" variant="subtitle1" sx={{ fontWeight: 600 }}>Worked</Typography></Grid>
                    <Grid item sx={{ minWidth: "100px" }}><Typography component="span" variant="subtitle1" sx={{ fontWeight: 600 }}>Overtime</Typography></Grid>
                    <Grid item sx={{ minWidth: "100px" }}><Typography component="span" variant="subtitle1" sx={{ fontWeight: 600 }}>Breaktime</Typography></Grid>
                </Grid>
                {
                    applicationData?.map((appDataItem: ApplicationData, idx) => {
                        return (
                            <Grid container spacing={2}>
                                <Grid item sx={{ minWidth: "170px" }}>
                                    <Tooltip placement="top" title={appDataItem.oldWorkloadDepartmentId !== appDataItem.newWorkloadDepartmentId ? `${appDataItem.oldWorkloadDepartmentId} -> ${appDataItem.newWorkloadDepartmentId}` : ''}>
                                        <FormControl variant="standard" fullWidth sx={{ mb: '4px' }}>
                                            <Select
                                                margin="dense"
                                                id="workloadDepartmentId"
                                                type="text"
                                                value={appDataItem.newWorkloadDepartmentId}
                                                sx={{ color: appDataItem.oldWorkloadDepartmentId !== appDataItem.newWorkloadDepartmentId ? 'orange' : '' }}
                                                onChange={(event) => {
                                                    let appData = Array.from(applicationData);
                                                    let newWorkloadDepartmentIdValue = event.target.value;
                                                    appData[idx].newWorkloadDepartmentId = newWorkloadDepartmentIdValue;
                                                    appData[idx].newProject = "";
                                                    appData[idx].newLine = "";
                                                    appData[idx].newStation = "";
                                                    appData[idx].newIsSupport = props.employee.departmentId !== newWorkloadDepartmentIdValue ? true : false;
                                                    setApplicationData(appData);
                                                }}
                                            >
                                                {departmentList.map((department: Department) => <MenuItem key={department.departmentId} value={department.departmentId}>{department.departmentId}</MenuItem>)}
                                            </Select>
                                        </FormControl>
                                    </Tooltip>
                                </Grid>
                                <Grid item sx={{ minWidth: "200px" }}>
                                    <Tooltip placement="top" title={appDataItem.oldProject !== appDataItem.newProject ? `${appDataItem.oldProject} -> ${appDataItem.newProject}` : ''}>
                                        <FormControl variant="standard" fullWidth sx={{ mb: '4px' }}>
                                            <Select
                                                margin="dense"
                                                id="project"
                                                type="text"
                                                value={appDataItem.newProject}
                                                sx={{ color: appDataItem.oldProject !== appDataItem.newProject ? 'orange' : '' }}
                                                onChange={(event) => {
                                                    let appData = Array.from(applicationData);
                                                    let newProjectValue = event.target.value;
                                                    let lines = projectList.find(p => p.name === newProjectValue && p.departmentId === appDataItem.newWorkloadDepartmentId!)?.lines ?? [];
                                                    appData[idx].newProject = newProjectValue;
                                                    appData[idx].newLine = lines?.length === 1 ? lines[0].name : "";
                                                    appData[idx].newStation = "";
                                                    setApplicationData(appData);
                                                }}
                                            >
                                                {projectList.filter(p => p.departmentId === appDataItem.newWorkloadDepartmentId!).map((project: Project) => <MenuItem key={project.name} value={project.name}>{project.name}</MenuItem>)}
                                            </Select>
                                        </FormControl>
                                    </Tooltip>
                                </Grid>
                                <Grid item sx={{ minWidth: "60px" }}>
                                    <Tooltip placement="top" title={appDataItem.oldLine !== appDataItem.newLine ? `${appDataItem.oldLine} -> ${appDataItem.newLine}` : ''}>
                                        <FormControl variant="standard" fullWidth sx={{ mb: '4px' }}>
                                            <Select
                                                margin="dense"
                                                id="line"
                                                type="text"
                                                value={appDataItem.newLine}
                                                sx={{ color: appDataItem.oldLine !== appDataItem.newLine ? 'orange' : '' }}
                                                onChange={(event) => {
                                                    let appData = Array.from(applicationData);
                                                    let newLineValue = event.target.value;
                                                    appData[idx].newLine = newLineValue;
                                                    setApplicationData(appData);
                                                }}
                                            >
                                                {projectList.find(p => p.name === appDataItem.newProject && p.departmentId === appDataItem.newWorkloadDepartmentId!)?.lines.map((line: ProjectLine) => <MenuItem key={line.id} value={line.name}>{line.name}</MenuItem>)}
                                            </Select>
                                        </FormControl>
                                    </Tooltip>
                                </Grid>
                                <Grid item sx={{ minWidth: "200px" }}>
                                    <Tooltip placement="top" title={appDataItem.oldStation !== appDataItem.newStation ? `${appDataItem.oldStation} -> ${appDataItem.newStation}` : ''}>
                                        <FormControl variant="standard" fullWidth sx={{ mb: '4px' }}>
                                            <Select
                                                margin="dense"
                                                id="station"
                                                type="text"
                                                value={appDataItem.newStation}
                                                sx={{ color: appDataItem.oldStation !== appDataItem.newStation ? 'orange' : '' }}
                                                onChange={(event) => {
                                                    let appData = Array.from(applicationData);
                                                    let newStationValue = event.target.value;
                                                    appData[idx].newStation = newStationValue;
                                                    setApplicationData(appData);
                                                }}
                                            >
                                                {projectList.find(p => p.name === appDataItem.newProject && p.departmentId === appDataItem.newWorkloadDepartmentId!)?.lines.map(line => line.stations).flat(1).map((station: LineStation) => <MenuItem key={station.id} value={station.name}>{station.name}</MenuItem>)}
                                            </Select>
                                        </FormControl>
                                    </Tooltip>
                                </Grid>
                                <Grid item sx={{ minWidth: "100px" }}>
                                    <Tooltip placement="top" title={appDataItem.oldStart !== appDataItem.newStart ? `${appDataItem.oldStart} -> ${appDataItem.newStart}` : ''}>
                                        <TextField
                                            sx={{ maxWidth: "80px", mt: '0px', mb: '4px', input: { color: appDataItem.oldStart !== appDataItem.newStart ? 'orange' : '' } }}
                                            margin="dense"
                                            id="start"
                                            type="text"
                                            variant="standard"
                                            value={appDataItem.newStart}
                                            error={
                                                !/^([0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(appDataItem.newStart!)
                                                || moment(appDataItem.newStart, "H:mm").isSameOrAfter(moment(appDataItem.newEnd, "H:mm"))
                                                || (idx > 0 && moment(appDataItem.newStart, "H:mm").isBefore(moment(applicationData[idx - 1].newEnd, "H:mm")))
                                            }
                                            onChange={(event) => {
                                                setFieldStartOrEndIsDuringBreaktimeError(false);
                                                let appData = Array.from(applicationData);
                                                let newStartValue = event.target.value.replace('.', ':');

                                                if (/[a-z\s\.,\+-\/\\=()\*&\^%\$\#@\!`\~\|\[\]\{\}'";\<\>\?]/i.test(newStartValue)) return;
                                                if ((newStartValue!.match(/:/g) || []).length > 1) return;
                                                if (newStartValue.includes(":") && newStartValue.split(":")[0].length > 2) return;
                                                if (newStartValue.includes(":") && newStartValue.split(":")[1].length > 2) return;

                                                appData[idx].newStart = newStartValue;
                                                appData[idx].newWorked = calculateWorkedHours(appData[idx].newStart!, appData[idx].newEnd!, breaks);
                                                appData[idx].newOvertime = calculateOvertime(props.employee, appData[idx], dailyWorkloadApplication.day, breaks);
                                                appData[idx].newBreaktime = calculateBreaktime(appData[idx].newStart!, appData[idx].newEnd!, breaks);
                                                setApplicationData(appData);
                                            }}
                                        />
                                    </Tooltip>
                                </Grid>
                                <Grid item sx={{ minWidth: "100px" }}>
                                    <Tooltip placement="top" title={appDataItem.oldEnd !== appDataItem.newEnd ? `${appDataItem.oldEnd} -> ${appDataItem.newEnd}` : ''}>
                                        <TextField
                                            sx={{ maxWidth: "80px", mt: '0px', mb: '4px', input: { color: appDataItem.oldEnd !== appDataItem.newEnd ? 'orange' : '' } }}
                                            margin="dense"
                                            id="start"
                                            type="text"
                                            variant="standard"
                                            value={appDataItem.newEnd}
                                            error={
                                                !/^([0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(appDataItem.newEnd!)
                                                || moment(appDataItem.newStart, "H:mm").isSameOrAfter(moment(appDataItem.newEnd, "H:mm"))
                                                || (idx <= (applicationData.length - 2) && moment(appDataItem.newEnd, "H:mm").isAfter(moment(applicationData[idx + 1].newStart, "H:mm")))
                                            }
                                            onChange={(event) => {
                                                setFieldStartOrEndIsDuringBreaktimeError(false);
                                                let appData = Array.from(applicationData);
                                                let newEndValue = event.target.value.replace('.', ':');

                                                if (/[a-z\s\.,\+-\/\\=()\*&\^%\$\#@\!`\~\|\[\]\{\}'";\<\>\?]/i.test(newEndValue)) return;
                                                if ((newEndValue!.match(/:/g) || []).length > 1) return;
                                                if (newEndValue.includes(":") && newEndValue.split(":")[0].length > 2) return;
                                                if (newEndValue.includes(":") && newEndValue.split(":")[1].length > 2) return;

                                                appData[idx].newEnd = newEndValue;
                                                appData[idx].newWorked = calculateWorkedHours(appData[idx].newStart!, appData[idx].newEnd!, breaks);
                                                appData[idx].newOvertime = calculateOvertime(props.employee, appData[idx], dailyWorkloadApplication.day, breaks);
                                                appData[idx].newBreaktime = calculateBreaktime(appData[idx].newStart!, appData[idx].newEnd!, breaks);
                                                setApplicationData(appData);
                                            }}
                                        />
                                    </Tooltip>
                                </Grid>
                                <Grid item sx={{ minWidth: "100px" }}>
                                    <Tooltip placement="top" title={appDataItem.oldWorked !== appDataItem.newWorked ? `${appDataItem.oldWorked} -> ${appDataItem.newWorked}` : ''}>
                                        <TextField
                                            disabled
                                            sx={{ maxWidth: "80px", mt: '0px', mb: '4px', bgcolor: "#EEEEEE", input: { pl: "7px", color: appDataItem.oldWorked !== appDataItem.newWorked ? 'orange!important' : '', WebkitTextFillColor: 'inherit!important' } }}
                                            margin="dense"
                                            id="worked"
                                            type="text"
                                            variant="standard"
                                            value={appDataItem.newWorked}
                                        />
                                    </Tooltip>
                                </Grid>
                                <Grid item sx={{ minWidth: "100px" }}>
                                    <Tooltip placement="top" title={appDataItem.oldOvertime !== appDataItem.newOvertime ? `${appDataItem.oldOvertime} -> ${appDataItem.newOvertime}` : ''}>
                                        <TextField
                                            disabled
                                            sx={{ maxWidth: "80px", mt: '0px', mb: '4px', bgcolor: "#EEEEEE", input: { pl: "7px", color: appDataItem.oldOvertime !== appDataItem.newOvertime ? 'orange' : '', WebkitTextFillColor: 'inherit!important' } }}
                                            margin="dense"
                                            id="overtime"
                                            type="text"
                                            variant="standard"
                                            value={appDataItem.newOvertime}
                                        />
                                    </Tooltip>
                                </Grid>
                                <Grid item sx={{ minWidth: "100px" }}>
                                    <Tooltip placement="top" title={appDataItem.oldBreaktime !== appDataItem.newBreaktime ? `${appDataItem.oldBreaktime} -> ${appDataItem.newBreaktime}` : ''}>
                                        <TextField
                                            disabled
                                            sx={{ maxWidth: "80px", mt: '0px', mb: '4px', bgcolor: "#EEEEEE", input: { pl: "7px", color: appDataItem.oldBreaktime !== appDataItem.newBreaktime ? 'orange' : '', WebkitTextFillColor: 'inherit!important' } }}
                                            margin="dense"
                                            id="breaktime"
                                            type="text"
                                            variant="standard"
                                            value={appDataItem.newBreaktime}
                                        />
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        );
                    })
                }
                <Grid container sx={{ mt: "40px" }}>
                    <Grid item xs={12} lg={8}>
                        <Typography component="span" variant="subtitle1" sx={{ fontWeight: 600 }}>Reason for change</Typography>
                        <Typography component="span" variant="subtitle1"> (</Typography>
                        <Typography component="span" variant="subtitle1" sx={{ fontWeight: 600, color: "red" }}>*</Typography>
                        <Typography component="span" variant="subtitle1">required)</Typography>
                        <TextField
                            id="reason"
                            fullWidth
                            type="text"
                            variant="outlined"
                            margin="dense"
                            multiline
                            rows={4}
                            placeholder="Specify a reason why you are applying for the change. This field is required."
                            value={dailyWorkloadApplication.reason}
                            error={fieldReasonHasError}
                            onChange={(event) => {
                                if (fieldReasonHasError && event.target.value !== "") {
                                    setFieldReasonHasError(false);
                                }
                                setDailyWorkloadApplication({ ...dailyWorkloadApplication, reason: event.target.value });
                            }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClickCancelDialog}>Cancel</Button>
                <Button variant="contained" onClick={handleClickApply}>{iAmLeader ? "Save changes" : "Apply for changes"}</Button>
            </DialogActions>
            <Typography component="div" variant="body2" sx={{ color: "red", marginLeft: "auto", marginRight: "10px", padding: "5px" }}>{hasNoChangeError ? 'Nothing has changed there!' : ''}</Typography>
        </Dialog>
    );
}