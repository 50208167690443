import { Error, Warning } from "@mui/icons-material";
import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";

interface DailyWorkloadStatsProps {
    workloadStats: any,
}

export default function DailyWorkloadStats(props: DailyWorkloadStatsProps) {

    if (props.workloadStats === undefined) return <></>

    if (props.workloadStats.message) {
        return (
            <>
                <List sx={{ mt: '-5px' }}>
                    <ListItem disablePadding>
                        <ListItemIcon>
                            {
                                props.workloadStats.message.includes("Waiting")
                                    ?
                                    <Warning fontSize="small" htmlColor="orange" />
                                    :
                                    <Error fontSize="small" htmlColor="#CC3259" />
                            }
                        </ListItemIcon>
                        <ListItemText primary="" />
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemText primary={props.workloadStats.message} />
                    </ListItem>
                </List>
            </>
        );
    }

    return (
        <List sx={{ mt: '-12px' }}>
            <ListItem disablePadding title="Regular worked hours">
                <ListItemText primary={props.workloadStats.regularWorkedHours ?? "0:00"} />
            </ListItem>
            <ListItem disablePadding title="Supported hours">
                <ListItemText primary={props.workloadStats.supportWorkedHours ?? "0:00"} />
            </ListItem>
            <ListItem disablePadding title="Overtime hours">
                <ListItemText primary={props.workloadStats.overtimeWorkedHours ?? "0:00"} />
            </ListItem>
        </List>
    );
}