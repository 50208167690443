import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { useState } from "react";
import { newGuid } from "wcz-layout";
import Project from "../../models/Project";
import ProjectLine from "../../models/ProjectLine";
import { useUpdateProject } from "../../queries/ProjectSettingsQueries";

interface AddLineDialogProps {
    readonly project: Project,
    readonly open: boolean,
    readonly setOpen: (isOpen: boolean) => void,
    readonly closeProjectMenu: VoidFunction
}

function createLine(): ProjectLine {
    return {
        id: newGuid(),
        name: "",
        stations: [],
        leaders: []
    }
}

export default function AddLineDialog(props: AddLineDialogProps) {
    const { project, open, setOpen, closeProjectMenu } = props;

    const { mutate: editProject } = useUpdateProject();

    const [line, setLine] = useState(createLine());
    function onSetName(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>){
        setLine(prev => ({...prev, name: e.target.value }));
    }

    const handleClickCancelDialog = () => {
        setOpen(false);
        setLine(createLine());
        closeProjectMenu();
    };

    function onAdd(){ 
        const editedProject = {...project, lines: [...project.lines, line]};
        editProject(editedProject, { onSuccess: handleClickCancelDialog });
    }

    return (
        <Dialog open={open} fullWidth>
            <DialogTitle>Add line</DialogTitle>

            <DialogContent>
                <TextField
                    variant="standard"
                    margin="dense"
                    id="name"
                    label={<>Line name<span style={{ color: "red" }}>*</span></>}
                    type="text"
                    value={line.name}
                    fullWidth
                    onChange={onSetName}
                />
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClickCancelDialog}>Cancel</Button>
                <Button variant="contained" onClick={onAdd}>Save</Button>
            </DialogActions>
        </Dialog>
    );
}