import { Add, Delete } from "@mui/icons-material";
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, IconButton, MenuItem, Select, TextField, Tooltip, Typography } from "@mui/material";
import moment from "moment";
import { SetStateAction, useContext, useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { fetchPost, LayoutContext } from "wcz-layout";
import ApplicationData from "../../models/ApplicationData";
import { ApprovalStatus } from "../../models/ApprovalStatus";
import Break from "../../models/Break";
import DailyWorkloadApplication from "../../models/DailyWorkloadApplication";
import DailyWorkloadModel from "../../models/DailyWorkloadModel";
import Department from "../../models/Department";
import Head from "../../models/Head";
import HeadcountSummaryModel from "../../models/HeadcountSummaryModel";
import { apiUrl } from "../../utils/BaseUrl";
import { ProjectSettingsContext } from "../../contexts/ProjectSettingsContext";
import ProjectLine from "../../models/ProjectLine";
import LineStation from "../../models/LineStation";
import Project from "../../models/Project";
import { useGetDepartments } from "../../queries/DepartmentQueries";

function calculateWorkedHours(start: string, end: string, breaks: Break[]): string {
    if (!start || !end) return "";

    let workStart = moment(start, "H:mm");
    let workEnd = moment(end, "H:mm");

    let workDiff = workEnd.diff(workStart);    
    let breakDiff = 0;

    //let breaks: Break[] = breakSchedule.filter(b => b.departmentId === employee.departmentId && b.project === employee.project && b.line === employee.line);    
    for (let varBreak of breaks) {
        let breakStart = moment(varBreak.start, "H:mm");
        let breakEnd = moment(varBreak.end, "H:mm");

        // Work start and end time are both in the middle of the break
        if (workStart.isSameOrAfter(breakStart) && workEnd.isSameOrBefore(breakEnd)) {
            workDiff = 0;
            break;
        }

        // #1: The whole break is in the middle of the working time
        if (workStart.isBefore(breakStart) && workEnd.isAfter(breakEnd)) {
            breakDiff += breakEnd.diff(breakStart);
        }
        // #2: The work start is in the middle of the break or is the same as the break start time
        else if (workStart.isBetween(breakStart, breakEnd) || workStart.isSame(breakStart)) {
            breakDiff += breakEnd.diff(workStart);
        }
        // #3: The work end is in the middle of the break or is the same as the break end time
        else if (workEnd.isBetween(breakStart, breakEnd) || workEnd.isSame(breakEnd)) {
            breakDiff += workEnd.diff(breakStart);
        }
    }

    let workAndBreakDiffsInHours = moment.duration(workDiff - breakDiff);
    return moment.utc(workAndBreakDiffsInHours.asMilliseconds()).format("H:mm");
}

function calculateOvertime(employee: HeadcountSummaryModel, appDataItem: ApplicationData, dayToEdit: string, breaks: Break[]): string {
    if (!employee.workshiftEnd || employee.workshiftEnd === "00:00") return "";
    if (!appDataItem.newStart || !appDataItem.newEnd) return "";

    const isWeekend: boolean = moment(dayToEdit).day() === 0 || moment(dayToEdit).day() === 6 ? true : false;
    const isHoliday: boolean = false; //TODO: call service to get if is bank holiday
    if (isWeekend || isHoliday) return appDataItem.newWorked ?? "";

    let workStart = moment(appDataItem.newStart, "H:mm");
    let workEnd = moment(appDataItem.newEnd, "H:mm");
    let workshiftEnd = moment(employee.workshiftEnd, "H:mm");
    let overtime = "";
    if (workEnd.isAfter(workshiftEnd)) {
        if (workStart.isAfter(workshiftEnd)) {
            overtime = moment(workEnd).subtract(moment.duration(appDataItem.newStart)).format("H:mm");
        }
        else {
            overtime = moment(workEnd).subtract(moment.duration(employee.workshiftEnd)).format("H:mm");
        }

        let breaktimeDuration = 0;
        let overtimeBreaks: Break[] = breaks.filter(b => b.type === "Overtime1" || b.type === "Overtime2");
        overtimeBreaks.forEach((element) => {
            let breakStart = moment(element.start, "H:mm");
            let breakEnd = moment(element.end, "H:mm");
            if (breakStart.isAfter(workStart) && breakEnd.isSameOrBefore(workEnd)) {
                breaktimeDuration += element.durationInMinutes;
            }
        });

        if (breaktimeDuration) {
            overtime = moment(overtime, "H:mm").subtract(moment.duration(breaktimeDuration, "minutes")).format("H:mm");
        }
    }

    return overtime;
}

function calculateBreaktime(start: string, end: string, breaks: Break[]): string {
    if (!start || !end) return "";

    let workStart = moment(start, "H:mm");
    let workEnd = moment(end, "H:mm");

    //let breaks: Break[] = breakSchedule.filter(b => b.departmentId === employee.departmentId && b.project === employee.project && b.line === employee.line);

    let breakDiff = 0;
    for (let varBreak of breaks) {
        let breakStart = moment(varBreak.start, "H:mm");
        let breakEnd = moment(varBreak.end, "H:mm");  

        // Work start and end time are both in the middle of the break
        if (workStart.isSameOrAfter(breakStart) && workEnd.isSameOrBefore(breakEnd)) {
            breakDiff = workEnd.diff(workStart);;
            break;
        }

        // #1: The whole break is in the middle of the working time
        if (workStart.isBefore(breakStart) && workEnd.isAfter(breakEnd)) {
            breakDiff += breakEnd.diff(breakStart);
        }
        // #2: The work start is in the middle of the break or is the same as the break start time
        else if (workStart.isBetween(breakStart, breakEnd) || workStart.isSame(breakStart)) {
            breakDiff += breakEnd.diff(workStart);
        }
        // #3: The work end is in the middle of the break or is the same as the break end time
        else if (workEnd.isBetween(breakStart, breakEnd) || workEnd.isSame(breakEnd)) {
            breakDiff += workEnd.diff(breakStart);
        }
    }

    if (breakDiff === 0) {
        return "";
    }

    let breakDiffInHours = moment.duration(breakDiff);
    return moment.utc(breakDiffInHours.asMilliseconds()).format("H:mm");
}

interface CreateDailyWorkloadApplicationDialogProps {
    dayToEdit: string,
    setSetDayToEdit: (day: string) => void,
    rows: DailyWorkloadModel[],
    employee: HeadcountSummaryModel,
    headsOfEmployee: Head[],
    breaks: Break[],
    setDailyWorkloadApplications: (applicationArray: SetStateAction<DailyWorkloadApplication[]>) => void,
}

export default function CreateDailyWorkloadApplicationDialog(props: CreateDailyWorkloadApplicationDialogProps) {    
    const { user } = useContext(LayoutContext);
    const { projectList } = useContext(ProjectSettingsContext);
    const { breaks } = props;

    const { data: departmentList = [] } = useGetDepartments();

    const [applicationData, setApplicationData] = useState<ApplicationData[]>([] as ApplicationData[]);
    const [isGymVisitChecked, setIsGymVisitChecked] = useState(false);
    const [reason, setReason] = useState("");
    const iAmLeader: boolean = props.headsOfEmployee.some(head => head.employeeId === user.id); // || user.id === "C1107048";

    // Dialog: Apply for changes - validation variables
    const [hasNoChangeError, setHasNoChangeError] = useState(false);
    const [fieldReasonHasError, setFieldReasonHasError] = useState(false);

    useEffect(() => {
        let dailyWorkloads = props.rows.filter(r => r.day === props.dayToEdit);
        let appData: ApplicationData[] = [];
        dailyWorkloads.forEach((element) => {
            let appDataItem: ApplicationData = {} as ApplicationData;
            appDataItem.dailyWorkloadId = (element.id!.includes("noR-") || element.id!.includes("noA-")) ? undefined : element.id!;
            appDataItem.oldWorkloadDepartmentId = element.workloadDepartmentId;
            appDataItem.newWorkloadDepartmentId = element.workloadDepartmentId;
            appDataItem.oldProject = element.project;
            appDataItem.newProject = element.project;
            appDataItem.oldLine = element.line;
            appDataItem.newLine = element.line;
            appDataItem.oldStation = element.station;
            appDataItem.newStation = element.station;
            appDataItem.oldStart = element.start ?? "";
            appDataItem.newStart = element.start ?? "";
            appDataItem.oldEnd = element.end ?? "";
            appDataItem.newEnd = element.end ?? "";
            appDataItem.oldWorked = element.worked ?? "";
            appDataItem.newWorked = element.worked ?? "";
            appDataItem.oldOvertime = element.overtime ?? "";
            appDataItem.newOvertime = element.overtime ?? "";
            appDataItem.oldBreaktime = element.breaktime ?? "";
            appDataItem.newBreaktime = element.breaktime ?? "";
            appDataItem.oldIsSupport = element.isSupport;
            appDataItem.newIsSupport = element.isSupport;
            appDataItem.canBeDeleted = false;
            appDataItem.isNew = true;

            appData.push(appDataItem);
        });

        setApplicationData(appData);

    }, [props.dayToEdit]);

    useEffect(() => {
        if (hasNoChangeError) {
            setTimeout(() => setHasNoChangeError(false), 5000);
        }
    }, [hasNoChangeError]);

    const handleClickNewRow = () => {
        let previousWorkEnd = applicationData[applicationData.length - 1].newEnd;
        let newAppDataItem: ApplicationData = {
            newWorkloadDepartmentId: props.employee.departmentId,
            newProject: props.employee.project,
            newLine: props.employee.line,
            newStation: props.employee.station,
            newStart: previousWorkEnd ?? "",
            newEnd: "",
            newWorked: "",
            newOvertime: "",
            newBreaktime: "",
            newIsSupport: false,
            canBeDeleted: true,
            isNew: true
        };

        setApplicationData([...applicationData, newAppDataItem]);
    };

    const handleClickDeleteRow = (i: number) => {
        let newAppData = Array.from(applicationData);
        newAppData.splice(i, 1);
        setApplicationData(newAppData);
    };

    const handleClickCancelDialog = () => {
        cleanValidation();
        setReason(""); //TODO: find better way how to clear this state variable
        props.setSetDayToEdit("");
    };

    const handleGymCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsGymVisitChecked(event.target.checked);

        if (event.target.checked) {
            setReason("I visited the company gym and haven't been working on that day.");
        }
        else {
            setReason("");
        }
    };

    const nothingChanged = () => {
        if (isGymVisitChecked) return false;

        return applicationData.every(element => 
            element.oldWorkloadDepartmentId === element.newWorkloadDepartmentId
            && element.oldProject === element.newProject
            && element.oldLine === element.newLine
            && element.oldStation === element.newStation
            && element.oldStart === element.newStart
            && element.oldEnd === element.newEnd
            && element.oldWorked === element.newWorked
            && element.oldOvertime === element.newOvertime
            && element.oldBreaktime === element.newBreaktime
            && element.oldIsSupport === element.newIsSupport
        );
    };

    const canBeSaved = () => {
        let result = true;

        if (!reason.trim()) {
            setFieldReasonHasError(true);
            return false;
        }

        if (nothingChanged()) {
            setHasNoChangeError(true);
            return false;
        }        

        if (!isGymVisitChecked) {
            applicationData.forEach((appDataItem, idx) => {
                if (!appDataItem.newWorkloadDepartmentId)
                    result = false;
                if (!appDataItem.newProject)
                    result = false;
                if (!appDataItem.newLine)
                    result = false;
                if (!appDataItem.newStation)
                    result = false;
                if (!/^([0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(appDataItem.newStart!))
                    result = false;
                if (moment(appDataItem.newStart, "H:mm").isSameOrAfter(moment(appDataItem.newEnd, "H:mm")))
                    result = false;
                if (idx > 0 && moment(appDataItem.newStart, "H:mm").isBefore(moment(applicationData[idx - 1].newEnd, "H:mm")))
                    result = false;
                if (!/^([0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(appDataItem.newEnd!))
                    result = false;
                if (idx <= (applicationData.length - 2) && moment(appDataItem.newEnd, "H:mm").isAfter(moment(applicationData[idx + 1].newStart, "H:mm")))
                    result = false;
            });        
        }

        return result;
    };

    const cleanValidation = () => {
        setFieldReasonHasError(false);
    };  

    const { mutate: postNewApplication } = useMutation((request: DailyWorkloadApplication) => fetchPost(`${apiUrl}/v1/DailyWorkloadApplication`, request), {
        onSuccess: (newApplication: DailyWorkloadApplication) => props.setDailyWorkloadApplications(prevState => prevState ? [...prevState, newApplication] : [newApplication])
    });

    const handleClickApply = () => {
        if (!canBeSaved()) return;

        let employeeLeader = props.headsOfEmployee.find(h => h.level === "Leader");
        if (!employeeLeader) return; //TODO: tady by to melo vypsat chybovou hlasku, ze nebylo nalezeno, kdo je Leader zamestnance

        var newApplication: DailyWorkloadApplication = {
            day: props.dayToEdit,
            employeeId: props.employee.employeeId,
            employeeName: `${props.employee.firstName} ${props.employee.lastName}`,
            employeeDepartmentId: props.employee.departmentId,
            applicationData: !isGymVisitChecked ? applicationData : undefined,
            isGymVisit: isGymVisitChecked,
            reason: reason,
            status: iAmLeader ? ApprovalStatus.Approved : ApprovalStatus.WaitingForApproval,
            approverEmployeeId: employeeLeader!.employeeId,
            approverName: employeeLeader!.name,
            approverComment: ""
        };

        postNewApplication(newApplication);
        cleanValidation();
        setReason(""); //TODO: find better way how to clear this state variable
        props.setSetDayToEdit("");
    };

    return (
        <Dialog open={!!props.dayToEdit} maxWidth={"xl"}>
            <DialogTitle>Edit Daily Workload on: {moment(props.dayToEdit).format("MM/DD dddd")}</DialogTitle>

            <DialogContent>
                <Grid container spacing={2} sx={{ mb: "10px" }}>
                    <Grid item><Typography component="span" variant="subtitle1" sx={{ fontWeight: 600 }}>Breaks:</Typography></Grid>
                    <Grid item>{breaks[0]?.start} - {breaks[0]?.end}</Grid>
                    <Grid item>{breaks[1]?.start} - {breaks[1]?.end}</Grid>
                    <Grid item>{breaks[2]?.start} - {breaks[2]?.end}</Grid>
                    <Grid item>{breaks[3]?.start} - {breaks[3]?.end}</Grid>
                </Grid>
                <Grid container spacing={2} sx={{ borderBottom: "3px solid #005363", mb: "10px" }}>
                    <Grid item sx={{ minWidth: "170px" }}><Typography component="span" variant="subtitle1" sx={{ fontWeight: 600 }}>Department Code</Typography></Grid>
                    <Grid item sx={{ minWidth: "200px" }}><Typography component="span" variant="subtitle1" sx={{ fontWeight: 600 }}>Project</Typography></Grid>
                    <Grid item sx={{ minWidth: "60px" }}><Typography component="span" variant="subtitle1" sx={{ fontWeight: 600 }}>Line</Typography></Grid>
                    <Grid item sx={{ minWidth: "200px" }}><Typography component="span" variant="subtitle1" sx={{ fontWeight: 600 }}>Station</Typography></Grid>
                    <Grid item sx={{ minWidth: "100px" }}><Typography component="span" variant="subtitle1" sx={{ fontWeight: 600 }}>Start</Typography></Grid>
                    <Grid item sx={{ minWidth: "100px" }}><Typography component="span" variant="subtitle1" sx={{ fontWeight: 600 }}>End</Typography></Grid>                    
                    <Grid item sx={{ minWidth: "100px" }}><Typography component="span" variant="subtitle1" sx={{ fontWeight: 600 }}>Worked</Typography></Grid>                    
                    <Grid item sx={{ minWidth: "100px" }}><Typography component="span" variant="subtitle1" sx={{ fontWeight: 600 }}>Overtime</Typography></Grid>                    
                    <Grid item sx={{ minWidth: "100px" }}><Typography component="span" variant="subtitle1" sx={{ fontWeight: 600 }}>Breaktime</Typography></Grid>                    
                </Grid>
                {
                    applicationData.map((appDataItem: ApplicationData, idx) => {
                        return (
                            <Grid container spacing={2}>
                                <Grid item sx={{ minWidth: "170px" }}>
                                    <Tooltip placement="top" title={appDataItem.oldWorkloadDepartmentId !== appDataItem.newWorkloadDepartmentId ? `${appDataItem.oldWorkloadDepartmentId} -> ${appDataItem.newWorkloadDepartmentId}` : ''}>
                                    <FormControl variant="standard" fullWidth sx={{ mb: '4px' }}>
                                        <Select
                                            margin="dense"
                                            id="workloadDepartmentId"
                                            type="text"
                                            value={appDataItem.newWorkloadDepartmentId}
                                            error={!isGymVisitChecked && !appDataItem.newWorkloadDepartmentId}
                                            sx={{ color: appDataItem.oldWorkloadDepartmentId !== appDataItem.newWorkloadDepartmentId ? 'orange' : '' }}                                            
                                            onChange={(event) => {
                                                let appData = Array.from(applicationData);
                                                let newWorkloadDepartmentIdValue = event.target.value;
                                                appData[idx].newWorkloadDepartmentId = newWorkloadDepartmentIdValue;
                                                appData[idx].newProject = "";
                                                appData[idx].newLine = "";
                                                appData[idx].newStation = "";
                                                appData[idx].newIsSupport = props.employee.departmentId !== newWorkloadDepartmentIdValue ? true : false;
                                                setApplicationData(appData);
                                            }}
                                        >
                                            {departmentList.map((department: Department) => <MenuItem key={department.departmentId} value={department.departmentId}>{department.departmentId}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                    </Tooltip>
                                </Grid>
                                <Grid item sx={{ minWidth: "200px" }}>
                                    <Tooltip placement="top" title={appDataItem.oldProject !== appDataItem.newProject ? `${appDataItem.oldProject} -> ${appDataItem.newProject}` : ''}>
                                    <FormControl variant="standard" fullWidth sx={{ mb: '4px' }}>
                                        <Select
                                            margin="dense"
                                            id="project"
                                            type="text"
                                            value={appDataItem.newProject}
                                            error={!isGymVisitChecked && !appDataItem.newProject}
                                            sx={{ color: appDataItem.oldProject !== appDataItem.newProject ? 'orange' : '' }}
                                            onChange={(event) => {
                                                let appData = Array.from(applicationData);
                                                let newProjectValue = event.target.value;
                                                let lines = projectList.find(p => p.name === newProjectValue && p.departmentId === appDataItem.newWorkloadDepartmentId!)?.lines ?? [];
                                                appData[idx].newProject = newProjectValue;
                                                appData[idx].newLine = lines?.length === 1 ? lines[0].name : "";
                                                appData[idx].newStation = "";
                                                setApplicationData(appData);
                                            }}
                                        >
                                            {projectList.filter(p => p.departmentId === appDataItem.newWorkloadDepartmentId!).map((project: Project) => <MenuItem key={project.name} value={project.name}>{project.name}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                    </Tooltip>
                                </Grid>
                                <Grid item sx={{ minWidth: "60px" }}>
                                    <Tooltip placement="top" title={appDataItem.oldLine !== appDataItem.newLine ? `${appDataItem.oldLine} -> ${appDataItem.newLine}` : ''}>
                                    <FormControl variant="standard" fullWidth sx={{ mb: '4px' }}>
                                        <Select
                                            margin="dense"
                                            id="line"
                                            type="text"
                                            value={appDataItem.newLine}
                                            error={!isGymVisitChecked && !appDataItem.newLine}
                                            sx={{ color: appDataItem.oldLine !== appDataItem.newLine ? 'orange' : '' }}
                                            onChange={(event) => {
                                                let appData = Array.from(applicationData);
                                                let newLineValue = event.target.value;
                                                appData[idx].newLine = newLineValue;
                                                setApplicationData(appData);
                                            }}
                                        >
                                            {projectList.find(p => p.name === appDataItem.newProject && p.departmentId === appDataItem.newWorkloadDepartmentId!)?.lines.map((line: ProjectLine) => <MenuItem key={line.id} value={line.name}>{line.name}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                    </Tooltip>
                                </Grid>
                                <Grid item sx={{ minWidth: "200px" }}>
                                    <Tooltip placement="top" title={appDataItem.oldStation !== appDataItem.newStation ? `${appDataItem.oldStation} -> ${appDataItem.newStation}` : ''}>
                                    <FormControl variant="standard" fullWidth sx={{ mb: '4px' }}>
                                        <Select
                                            margin="dense"
                                            id="station"
                                            type="text"
                                            value={appDataItem.newStation}
                                            error={!isGymVisitChecked && !appDataItem.newStation}
                                            sx={{ color: appDataItem.oldStation !== appDataItem.newStation ? 'orange' : '' }}
                                            onChange={(event) => {
                                                let appData = Array.from(applicationData);
                                                let newStationValue = event.target.value;
                                                appData[idx].newStation = newStationValue;
                                                setApplicationData(appData);
                                            }}
                                        >
                                            {projectList.find(p => p.name === appDataItem.newProject && p.departmentId === appDataItem.newWorkloadDepartmentId!)?.lines.map(line => line.stations).flat(1).map((station: LineStation) => <MenuItem key={station.id} value={station.name}>{station.name}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                    </Tooltip>
                                </Grid>
                                <Grid item sx={{ minWidth: "100px" }}>
                                    <Tooltip placement="top" title={appDataItem.oldStart !== appDataItem.newStart ? `${appDataItem.oldStart} -> ${appDataItem.newStart}` : ''}>
                                    <TextField
                                        sx={{ maxWidth: "80px", mt: '0px', mb: '4px', input: { color: appDataItem.oldStart !== appDataItem.newStart ? 'orange' : '' } }}                                        
                                        margin="dense"
                                        id="start"
                                        type="text"
                                        variant="standard"
                                        value={appDataItem.newStart}
                                        error={
                                            !isGymVisitChecked
                                            && ( !/^([0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(appDataItem.newStart!)
                                                || moment(appDataItem.newStart, "H:mm").isSameOrAfter(moment(appDataItem.newEnd, "H:mm"))
                                                || (idx > 0 && moment(appDataItem.newStart, "H:mm").isBefore(moment(applicationData[idx - 1].newEnd, "H:mm")))
                                            )
                                        }
                                        onChange={(event) => {
                                            let appData = Array.from(applicationData);
                                            let newStartValue = event.target.value.replace('.', ':');

                                            if (/[a-z\s\.,\+-\/\\=()\*&\^%\$\#@\!`\~\|\[\]\{\}'";\<\>\?]/i.test(newStartValue)) return;
                                            if ((newStartValue!.match(/:/g) || []).length > 1) return;
                                            if (newStartValue.includes(":") && newStartValue.split(":")[0].length > 2) return;
                                            if (newStartValue.includes(":") && newStartValue.split(":")[1].length > 2) return;                                            

                                            appData[idx].newStart = newStartValue;
                                            appData[idx].newWorked = calculateWorkedHours(appData[idx].newStart!, appData[idx].newEnd!, breaks);
                                            appData[idx].newOvertime = calculateOvertime(props.employee, appData[idx], props.dayToEdit, breaks);
                                            appData[idx].newBreaktime = calculateBreaktime(appData[idx].newStart!, appData[idx].newEnd!, breaks);
                                            setApplicationData(appData);
                                        }}
                                    />
                                    </Tooltip>
                                </Grid>
                                <Grid item sx={{ minWidth: "100px" }}>
                                    <Tooltip placement="top" title={appDataItem.oldEnd !== appDataItem.newEnd ? `${appDataItem.oldEnd} -> ${appDataItem.newEnd}` : ''}>
                                    <TextField
                                        sx={{ maxWidth: "80px", mt: '0px', mb: '4px', input: { color: appDataItem.oldEnd !== appDataItem.newEnd ? 'orange' : '' } }}                                        
                                        margin="dense"
                                        id="end"
                                        type="text"
                                        variant="standard"
                                        value={appDataItem.newEnd}
                                        error={
                                            !isGymVisitChecked
                                            && (!/^([0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(appDataItem.newEnd!)
                                                || moment(appDataItem.newStart, "H:mm").isSameOrAfter(moment(appDataItem.newEnd, "H:mm"))
                                                || (idx <= (applicationData.length - 2) && moment(appDataItem.newEnd, "H:mm").isAfter(moment(applicationData[idx + 1].newStart, "H:mm")))
                                            )
                                        }
                                        onChange={(event) => {
                                            let appData = Array.from(applicationData);
                                            let newEndValue = event.target.value.replace('.', ':');                                            

                                            if (/[a-z\s\.,\+-\/\\=()\*&\^%\$\#@\!`\~\|\[\]\{\}'";\<\>\?]/i.test(newEndValue)) return;                                            
                                            if ((newEndValue!.match(/:/g) || []).length > 1) return;
                                            if (newEndValue.includes(":") && newEndValue.split(":")[0].length > 2) return;
                                            if (newEndValue.includes(":") && newEndValue.split(":")[1].length > 2) return;

                                            appData[idx].newEnd = newEndValue;
                                            appData[idx].newWorked = calculateWorkedHours(appData[idx].newStart!, appData[idx].newEnd!, breaks);
                                            appData[idx].newOvertime = calculateOvertime(props.employee, appData[idx], props.dayToEdit, breaks);
                                            appData[idx].newBreaktime = calculateBreaktime(appData[idx].newStart!, appData[idx].newEnd!, breaks);
                                            setApplicationData(appData);
                                        }}
                                    />
                                    </Tooltip>
                                </Grid>
                                <Grid item sx={{ minWidth: "100px" }}>
                                    <Tooltip placement="top" title={appDataItem.oldWorked !== appDataItem.newWorked ? `${appDataItem.oldWorked} -> ${appDataItem.newWorked}` : ''}>
                                    <TextField
                                        disabled
                                        sx={{ maxWidth: "80px", mt: '0px', mb: '4px', bgcolor: "#EEEEEE", input: { pl: "7px", color: appDataItem.oldWorked !== appDataItem.newWorked ? 'orange!important' : '', WebkitTextFillColor: 'inherit!important' } }}
                                        margin="dense"
                                        id="worked"
                                        type="text"
                                        variant="standard"
                                        value={appDataItem.newWorked}
                                    />
                                    </Tooltip>
                                </Grid>
                                <Grid item sx={{ minWidth: "100px" }}>
                                    <Tooltip placement="top" title={appDataItem.oldOvertime !== appDataItem.newOvertime ? `${appDataItem.oldOvertime} -> ${appDataItem.newOvertime}` : ''}>
                                    <TextField
                                        disabled
                                        sx={{ maxWidth: "80px", mt: '0px', mb: '4px', bgcolor: "#EEEEEE", input: { pl: "7px", color: appDataItem.oldOvertime !== appDataItem.newOvertime ? 'orange' : '', WebkitTextFillColor: 'inherit!important' } }}
                                        margin="dense"
                                        id="overtime"
                                        type="text"
                                        variant="standard"
                                        value={appDataItem.newOvertime}
                                    />
                                    </Tooltip>
                                </Grid>
                                <Grid item sx={{ minWidth: "100px" }}>
                                    <Tooltip placement="top" title={appDataItem.oldBreaktime !== appDataItem.newBreaktime ? `${appDataItem.oldBreaktime} -> ${appDataItem.newBreaktime}` : ''}>
                                    <TextField
                                        disabled
                                        sx={{ maxWidth: "80px", mt: '0px', mb: '4px', bgcolor: "#EEEEEE", input: { pl: "7px", color: appDataItem.oldBreaktime !== appDataItem.newBreaktime ? 'orange' : '', WebkitTextFillColor: 'inherit!important' } }}
                                        margin="dense"
                                        id="breaktime"
                                        type="text"
                                        variant="standard"
                                        value={appDataItem.newBreaktime}
                                    />
                                    </Tooltip>
                                </Grid>
                                <Grid item sx={{ minWidth: "50px" }}>
                                {
                                    appDataItem.canBeDeleted &&
                                    <IconButton sx={{ mt: "-3px" }} onClick={() => handleClickDeleteRow(idx)}><Delete fontSize="small" /></IconButton>
                                }
                                </Grid>
                            </Grid>
                        );
                    })
                }    
                <Grid container>
                    <Grid item>
                        <Button size="small" sx={{ ml: "-7px", mt: "5px" }} onClick={handleClickNewRow}><Add fontSize="small" sx={{ mb: "4px" }}/>New row</Button>
                    </Grid>
                </Grid>
                <Grid container sx={{ mt: "10px" }}>
                    <Grid item>
                        <FormControlLabel disabled={false} sx={{ color: "#1495F2" }} control={<Checkbox checked={isGymVisitChecked} onChange={handleGymCheckboxChange} sx={{ color: "#1495F2", '&.Mui-checked': { color: "#1495F2" } }} />} label="Visiting the company Gym" />
                    </Grid>
                </Grid>
                <Grid container sx={{ mt: "30px" }}>
                    <Grid item xs={12} lg={8}>
                        <Typography component="span" variant="subtitle1" sx={{ fontWeight: 600 }}>Reason for change</Typography>
                        <Typography component="span" variant="subtitle1"> (</Typography>
                        <Typography component="span" variant="subtitle1" sx={{ fontWeight: 600, color: "red" }}>*</Typography>
                        <Typography component="span" variant="subtitle1">required)</Typography>
                        <TextField
                            id="reason"
                            fullWidth
                            type="text"
                            variant="outlined"
                            margin="dense"
                            multiline
                            rows={4}
                            placeholder="Specify a reason why you are applying for the change. This field is required."
                            value={reason}
                            error={fieldReasonHasError}
                            onChange={(event) => {
                                if (fieldReasonHasError && event.target.value !== "") {
                                    setFieldReasonHasError(false);
                                }
                                setReason(event.target.value);
                            }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClickCancelDialog}>Cancel</Button>
                <Button variant="contained" onClick={handleClickApply}>{iAmLeader ? "Save changes" : "Apply for changes"}</Button>
            </DialogActions>
            <Typography component="div" variant="body2" sx={{ color: "red", marginLeft: "auto", marginRight: "10px", padding: "5px" }}>{hasNoChangeError ? 'Nothing has changed there!' : ''}</Typography>
        </Dialog>
    );
}