import { ReactNode, createContext, useMemo } from "react";
import Head from "../models/Head";
import Project from "../models/Project";
import { useGetProjects } from "../queries/ProjectSettingsQueries";

interface ProjectSettingsContextInterface {
    readonly projectList: Project[],
    readonly headList: Head[]
}

export const ProjectSettingsContext = createContext({} as ProjectSettingsContextInterface);

interface ProjectSettingsProviderProps {
    readonly projectList: Project[],
    readonly children: ReactNode
}

export function ProjectSettingsProvider(props: ProjectSettingsProviderProps) {
    const { projectList: initProjectList, children } = props;

    const { data: projectList = initProjectList } = useGetProjects();

    const headList: Head[] = useMemo(() => {
        const newHeadList: Head[] = [];
        projectList
            .forEach(project => 
                project.lines.forEach(line =>
                    line.leaders.forEach(leader => {
                        newHeadList.push({
                            employeeId: leader.employeeId,
                            name: leader.name,
                            level: leader.function,
                            departmentId: project.departmentId,
                            project: project.name,
                            line: line.name
                        })
                    })
                )
            );
        return newHeadList;
    }, [projectList]);

    const providerValue: ProjectSettingsContextInterface = useMemo(() => ({ projectList: projectList, headList: headList }), [projectList, headList]);

    return (
        <ProjectSettingsContext.Provider value={providerValue}>
            {children}
        </ProjectSettingsContext.Provider>
    );
}