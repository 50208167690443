import { Error, MoreTime, Support, Work } from "@mui/icons-material";
import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";

interface DailyWorkloadStatsLegendProps {
    errors: number,
}

export default function DailyWorkloadStatsLegend(props: DailyWorkloadStatsLegendProps) {

    return (
        <List sx={{ mt: '-12px' }} data-search={props.errors > 0 ? "error" : "" }>
            <ListItem disablePadding>
                <ListItemIcon><Work fontSize="small" color="primary" /></ListItemIcon>
                <ListItemText primary="Regular" />
            </ListItem >
            <ListItem disablePadding>
                <ListItemIcon><Support fontSize="small" htmlColor="orange" /></ListItemIcon>
                <ListItemText primary="Support" />
            </ListItem>
            <ListItem disablePadding>
                <ListItemIcon><MoreTime fontSize="small" color="info" /></ListItemIcon>
                <ListItemText primary="Overtime" />
            </ListItem>
            {
                props.errors > 0 &&
                <ListItem disablePadding>
                    <ListItemIcon><Error fontSize="small" htmlColor="#CC3259" /></ListItemIcon>
                    <ListItemText primary={props.errors === 1 ? "1 error" : `${props.errors} errors`} />
                </ListItem>
            }
        </List>
    );
}