import { Error } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";


interface WarningDialogProps {
    open: boolean,
    setOpen: (open: boolean) => void,
}

export default function WarningDialog(props: WarningDialogProps) {

    function handleClickCancelDialog() {
        props.setOpen(false);
    };

    return (
        <Dialog open={props.open}>
            <DialogTitle sx={{ bgcolor: "#CC3259", color: "white", pl: '15px', pr: '10px' }}>
                <Grid container justifyContent="space-between">
                    <Grid item><Error sx={{ mb: '-5px' }} /> Error</Grid>
                </Grid>
            </DialogTitle>
            <DialogContent sx={{ minWidth: "475px", maxWidth: "475px" }}>
                <Typography variant="h6">There are some errors in your Daily Workload! Please help to fix it.</Typography>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" sx={{ bgcolor: "#CC3259" }} onClick={handleClickCancelDialog}>Ok, I will fix it!</Button>
            </DialogActions>
        </Dialog>
    );
}