import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { fetchGet, newGuid } from "wcz-layout";
import { EmployeeDto } from "../../models/EmployeeDto";
import { useContext, useMemo, useState } from "react";
import LineLeader from "../../models/LineLeader";
import ProjectLine from "../../models/ProjectLine";
import { ProjectSettingsContext } from "../../contexts/ProjectSettingsContext";
import Project from "../../models/Project";
import { useUpdateProject } from "../../queries/ProjectSettingsQueries";
import { useGetPsEmployees } from "../../queries/PsEmployeeQueries";

interface AddLeaderDialogProps {
    readonly open: boolean,
    readonly setOpen: (open: boolean) => void,
    readonly line: ProjectLine
}

function createLeader(): LineLeader{
    return {
        id: newGuid(),
        function: "",
        employeeId: "",
        name: ""
    }
}

export default function AddLeaderDialog(props: AddLeaderDialogProps) {
    const { open, setOpen, line } = props;

    const { projectList } = useContext(ProjectSettingsContext);

    const { data: employeeList = [] } = useGetPsEmployees({ enabled: open });

    const { mutate: editProject } = useUpdateProject();

    const [leader, setLeader] = useState<LineLeader>(createLeader());

    function onSetFunction(e: SelectChangeEvent<string>){
        setLeader(prev => ({...prev, function: e.target.value}));
    }

    function onChangeLeader(e: SelectChangeEvent<string>){
        const employee = employeeList.find(employee => employee.employeeId === e.target.value);
        if (!employee){ return; }
        setLeader(prev => ({
            ...prev,
            employeeId: employee.employeeId,
            name: employee.nameTitleCase
        }))
    }

    const handleClickCancelDialog = () => {
        setOpen(false);
        setLeader(createLeader());
    };

    const canAddLeader = useMemo(() => {
        return !!leader.name && !!leader.function && !!leader.employeeId;
    }, [leader]);

    function onAddLeader(){
        if (!canAddLeader){ return; }
        const project = projectList.find(project => project.lines.some(l => l.id === line.id));
        if (!project){ return; }
        const payload: Project = {
            ...project,
            lines: project.lines.map(l => l.id === line.id ? {...l, leaders: [...l.leaders, leader]} : l)
        };
        editProject(payload, { onSuccess: handleClickCancelDialog });
    }

    return (
        <Dialog open={open} fullWidth>
            <DialogTitle>Add leader</DialogTitle>

            <DialogContent>
                <FormControl variant="standard" fullWidth sx={{ mt: '8px', mb: '4px' }}>
                    <InputLabel id="function">Function<span style={{ color: "red" }}>*</span></InputLabel>
                    <Select
                        margin="dense"
                        id="function"
                        type="text"
                        value={leader.function}
                        fullWidth
                        onChange={onSetFunction}
                    >
                        <MenuItem value={"Leader"}>Leader</MenuItem>
                        <MenuItem value={"Supervisor"}>Supervisor</MenuItem>
                        <MenuItem value={"Manager"}>Manager</MenuItem>
                    </Select>
                </FormControl>
                <FormControl variant="standard" fullWidth sx={{ mt: '8px', mb: '4px' }}>
                    <InputLabel id="leaderEmployeeId">Leader<span style={{ color: "red" }}>*</span></InputLabel>
                    <Select
                        margin="dense"
                        id="leaderEmployeeId"
                        type="text"
                        value={leader.employeeId}
                        renderValue={() => `${leader.name} (${leader.employeeId})`}
                        fullWidth
                        onChange={onChangeLeader}
                    >
                        {employeeList.map((employee: EmployeeDto) => <MenuItem key={employee.employeeId} value={employee.employeeId}>{employee.nameTitleCase} ({employee.employeeId})</MenuItem>)}
                    </Select>
                </FormControl>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClickCancelDialog}>Cancel</Button>
                <Button variant="contained" disabled={!canAddLeader} onClick={onAddLeader}>Save</Button>
            </DialogActions>
        </Dialog>
    );
}