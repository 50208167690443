import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { useContext, useMemo, useState } from "react";
import { ProjectSettingsContext } from "../../contexts/ProjectSettingsContext";
import { useUpdateProject } from "../../queries/ProjectSettingsQueries";
import WarningText from "../common/WarningText";
import Project from "../../models/Project";

interface EditLineDialogProps {
    readonly lineId: string,
    readonly lineName: string,
    readonly setLineIdToEdit: (lineId: string) => void,
}

export default function EditLineDialog(props: EditLineDialogProps) {
    const { lineId, lineName, setLineIdToEdit } = props;

    const { projectList } = useContext(ProjectSettingsContext);
    const { mutate: editProject } = useUpdateProject();

    const [name, setName] = useState(lineName);

    const handleClickCancelDialog = () => {
        setLineIdToEdit("");
    };

    function onEdit(){ 
        const project = projectList.find(project => project.lines.some(line => line.id === lineId));
        if (!project){ return; }
        const lineIndex = project.lines.findIndex(line => line.id === lineId);
        if (lineIndex === -1){ return; }
        const editedLine = {...project.lines[lineIndex], name: name.length ? name : "-" };
        const editedProject: Project = {
            ...project,
            lines: project.lines.map(line => line.id === lineId ? editedLine : line),
            changeTracker: {
                oldDepartmentId: project.departmentId,
                oldProjectName: project.name,
                oldLineName: lineName,
                newNameValue: editedLine.name
            }
        };
        editProject(editedProject, { onSuccess: handleClickCancelDialog });
    }

    function onDelete(){
        const project = projectList.find(project => project.lines.some(line => line.id === lineId));
        if (!project){ return; }
        const shouldDelete = window.confirm(`Are you sure that you want to delete the line?`);
        if (!shouldDelete) { return; }
        const deletedProject: Project = {
            ...project,
            lines: project.lines.filter(l => l.id !== lineId),
            changeTracker: {
                oldDepartmentId:project.departmentId,
                oldProjectName: project.name,
                oldLineName: lineName
            }
        };
        editProject(deletedProject, { onSuccess: handleClickCancelDialog });
    }

    return (
        <Dialog open={Boolean(lineId)} fullWidth>
            <DialogTitle>Edit line</DialogTitle>

            <DialogContent>
                <TextField
                    variant="standard"
                    margin="dense"
                    id="name"
                    label={<>Line name<span style={{ color: "red" }}>*</span></>}
                    type="text"
                    value={name}
                    fullWidth
                    onChange={e => setName(e.target.value)}
                />

                <Box mt={2}>
                    <WarningText text="The line name will be automatically updated in the Headcount Summary table." mb={1} />
                    <WarningText text="The line name will be automatically updated for all records in the current month in the Daily Workload table." mb={1} />
                    <WarningText text="Deleting this line will result in removing it from all employees in the Headcount Summary table. Please assign a new line and a new station to the affected employees." />
                </Box>
            </DialogContent>

            <DialogActions sx={{ justifyContent: "space-between", mx: 1.5 }}>
                <Button onClick={onDelete} color="error">Delete</Button>

                <Box>
                    <Button onClick={handleClickCancelDialog}>Cancel</Button>
                    <Button variant="contained" onClick={onEdit}>Save</Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
}