import { Box, Button, CardContent, Chip, IconButton, Tab, Tabs, Typography } from "@mui/material";
import ProjectLine from "../../models/ProjectLine";
import { SyntheticEvent, useCallback, useMemo, useState } from "react";
import { Add, Delete, Dock, Edit, LinearScale } from "@mui/icons-material";
import { DataGridPro, GridActionsCellItem, GridColDef } from "@mui/x-data-grid-pro";
import { size } from "lodash";
import AddStationDialog from "./AddStationDialog";
import AddLeaderDialog from "./AddLeaderDialog";
import EditLineDialog from "./EditLineDialog";
import EditStationDialog from "./EditStationDialog";
import EditLeaderDialog from "./EditLeaderDialog";


interface LineSettingsProps {
    line: ProjectLine
}

export default function LineSettings(props: LineSettingsProps) {
    const { line } = props;

    const [activeTab, setActiveTab] = useState(0);
    const handleTabChange = useCallback((event: SyntheticEvent, newValue: number) => setActiveTab(newValue), []);

    const [leaderIdToEdit, setLeaderIdToEdit] = useState("");
    
    const lineLeaderTableColumns: GridColDef[] = useMemo(() => [
        { field: 'function', headerName: 'Funtion', width: 200 },
        { field: 'employeeId', headerName: 'Employee id', width: 200 },
        { field: 'name', headerName: 'Name', width: 200 },
        {
            field: 'actions', type: 'actions', width: 50, align: 'center',
            getActions: (params: any) => [
                <GridActionsCellItem icon={<Edit />} label="Delete" onClick={() => setLeaderIdToEdit(params.id)} />,
            ]
        },
    ] as GridColDef[], []); 

    const [lineIdToEdit, setLineIdToEdit] = useState<string>("");
    const handleClickEditLine = (lineId: string) => setLineIdToEdit(lineId);

    const [openAddStationDialog, setOpenAddStationDialog] = useState(false);
    const handleClickAddStation = () => setOpenAddStationDialog(true);
    const [stationIdToEdit, setStationIdToEdit] = useState<string>("");
    const handleClickEditStation = (stationId: string) => setStationIdToEdit(stationId);

    const [openAddLeaderDialog, setOpenAddLeaderDialog] = useState(false);
    const handleClickAddLeader = () => setOpenAddLeaderDialog(true);
    const handleClickDeleteLeader = (leaderId: string) => { alert("mazeme leadera: " + line.leaders.find(l => l.id === leaderId)?.name ?? "unknown"); };

    return (
        <CardContent>
            <Typography variant="h6" color="text.secondary" gutterBottom component="div">
                <IconButton id={line.id} onClick={() => handleClickEditLine(line.id)}>
                    <LinearScale color="secondary" />
                </IconButton>
                Line: {line.name}
            </Typography>
            {lineIdToEdit && <EditLineDialog lineId={lineIdToEdit} lineName={line.name} setLineIdToEdit={setLineIdToEdit} />}

            <Tabs value={activeTab} onChange={handleTabChange} sx={{ mb: "5px" }} >
                <Tab label="Stations" />
                <Tab label="Leaders" />
            </Tabs>
            {
                activeTab === 0 &&
                <Box>
                    {line.stations.map((station) => <Chip key={station.id} icon={<Dock />} onDelete={() => handleClickEditStation(station.id)} deleteIcon={<Edit />} color="primary" variant="filled" sx={{ m: "3px" }} label={station.name} />)}
                    <Chip sx={{ m: "3px" }} icon={<Add />} label="Add station" onClick={handleClickAddStation}  />
                    <AddStationDialog open={openAddStationDialog} setOpen={setOpenAddStationDialog} line={line} />
                    {stationIdToEdit && <EditStationDialog stationId={stationIdToEdit} setStationIdToEdit={setStationIdToEdit} />}
                </Box>
            }
            {
                activeTab === 1 &&
                <Box>
                    <Box sx={{ display: 'flex', width: '100%', '& .MuiDataGrid-columnHeaderTitle': { fontWeight: '600' } }}>
                        <DataGridPro columns={lineLeaderTableColumns} rows={line.leaders}
                            sx={{ padding: '10px 20px', border: '0px', minHeight: '200px', maxHeight: '400px' }}
                            density="compact"
                            hideFooter
                            disableRowSelectionOnClick
                            disableColumnMenu
                            disableColumnResize  
                        />
                    </Box>
                    <Button size="small" sx={{ ml: "20px" }} color="primary" startIcon={<Add />} onClick={handleClickAddLeader}>Add leader</Button>
                    <AddLeaderDialog open={openAddLeaderDialog} setOpen={setOpenAddLeaderDialog} line={line} />
                    {leaderIdToEdit && <EditLeaderDialog leaderId={leaderIdToEdit} setLeaderId={setLeaderIdToEdit} line={line} />}
                </Box>
            }
        </CardContent>
    );
}