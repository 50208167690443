import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import LineStation from "../../models/LineStation";
import { newGuid } from "wcz-layout";
import { useContext, useMemo, useState } from "react";
import { useUpdateProject } from "../../queries/ProjectSettingsQueries";
import ProjectLine from "../../models/ProjectLine";
import { ProjectSettingsContext } from "../../contexts/ProjectSettingsContext";

interface AddStationDialogProps {
    open: boolean,
    setOpen: (open: boolean) => void,
    line: ProjectLine
}

function createLineStation(): LineStation{
    return {
        id: newGuid(),
        name: ""
    };
}

export default function AddStationDialog(props: AddStationDialogProps) {
    const { open, setOpen, line } = props;

    const { projectList } = useContext(ProjectSettingsContext);

    const [lineStation, setLineStation] = useState(createLineStation());
    const { mutate: editProject } = useUpdateProject();

    function onChangeName(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>){
        setLineStation(prev => ({...prev, name: e.target.value}));
    }

    const handleClickCancelDialog = () => {
        setOpen(false);
        setLineStation(createLineStation());
    };

    const canCreate = useMemo(() => !!lineStation.name, [lineStation]);

    function onCreate(){
        const project = projectList.find(project => project.lines.some(l => l.id === line.id));
        if (!project){ return; }
        editProject({
            ...project,
            lines: project.lines.map(l => l.id === line.id ? {...line, stations: [...line.stations, lineStation]} : l)
        }, { onSuccess: handleClickCancelDialog});
    }

    return (
        <Dialog open={open} fullWidth>
            <DialogTitle>Add station</DialogTitle>

            <DialogContent>
                <TextField
                    variant="standard"
                    margin="dense"
                    id="name"
                    label={<>Station name<span style={{ color: "red" }}>*</span></>}
                    type="text"
                    value={lineStation.name}
                    fullWidth
                    onChange={onChangeName}
                />
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClickCancelDialog}>Cancel</Button>
                <Button variant="contained" disabled={!canCreate} onClick={onCreate}>Save</Button>
            </DialogActions>
        </Dialog>
    );
}