import { Box, Tooltip, Typography } from "@mui/material";
import { DataGridPro, GridColDef, GridToolbarContainer } from "@mui/x-data-grid-pro";
import { useMemo } from "react";
import { newGuid } from "wcz-layout";
import ProjectStatusCardModel from "../../models/ProjectStatusCardModel";


interface CustomToolbarProps {
    departmentId: string,
    project: string,
    line: string,
}

function CustomToolbar(props: CustomToolbarProps) {
    return (
        <GridToolbarContainer>
            <Typography variant="subtitle1" color="text.secondary" gutterBottom component="div">{props.departmentId} • {props.project} • {props.line}</Typography>
        </GridToolbarContainer>
    );
}

interface ProjectStatusCardProps {
    project: ProjectStatusCardModel,
}

export default function ProjectStatusCard(props: ProjectStatusCardProps) {
    const { project } = props;

    const projectTableColumns: GridColDef[] = useMemo(() => [
        {
            field: 'station', headerName: 'Station', flex: 1
        },
        {
            field: 'employeeCount', headerName: '#', width: 50,
            renderCell: (params) =>
                <Tooltip title={
                    <>
                        {project.employees.filter((empl: any) => empl.station === params.row.station).map((empl: any) => <div>{empl.employeeName}</div>)}
                    </>
                }>
                    <span>{params.value}</span>
                </Tooltip>
        }
    ] as GridColDef[], []);

    return (
        <Box
            sx={{
                display: 'flex', width: '100%',
                '& .MuiDataGrid-columnHeaderTitle': {
                    fontWeight: '600',
                },
            }}
        >
            <DataGridPro columns={projectTableColumns} rows={project.stations} density="compact" sx={{ padding: '15px 20px 20px 20px', minHeight: '450px', maxHeight: '450px', borderColor: '#BEC3C7' }}
                getRowId={() => newGuid()}
                components={{ Toolbar: CustomToolbar }}
                componentsProps={{
                    toolbar: { departmentId: project.departmentId, project: project.project, line: project.line }
                }}
                hideFooter
            />
        </Box>
    );
}