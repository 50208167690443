import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import { useMemo, useState } from "react";
import { newGuid } from "wcz-layout";
import Department from "../../models/Department";
import Project from "../../models/Project";
import { useGetPeoplesoftDepartments } from "../../queries/DepartmentQueries";
import { useCreateProject } from "../../queries/ProjectSettingsQueries";

interface AddProjectDialogProps {
    readonly open: boolean,
    readonly setOpen: (open: boolean) => void
}

function createProject(): Project{
    return {
        id: newGuid(),
        name: "",
        departmentId: "",
        lines: []
    };
}

export default function AddProjectDialog(props: AddProjectDialogProps) {
    const { open, setOpen } = props;

    const { data: departmentList = [] } = useGetPeoplesoftDepartments();

    const { mutate: addProject } = useCreateProject();

    const [project, setProject] = useState(createProject());

    function onChangeName(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>){
        setProject(prev => ({...prev, name: e.target.value}));
    }

    function onChangeDepartment(e: SelectChangeEvent<any>){
        setProject(prev => ({...prev, departmentId: e.target.value}))
    }

    const handleClickCancelDialog = () => {
        setOpen(false);
        setProject(createProject());
    };

    const canCreate = useMemo(() => !!project.name && !!project.departmentId, [project]);

    function onCreate(){
        if (!canCreate){ return; }
        addProject(project, { onSuccess: handleClickCancelDialog })
    }

    return (
        <Dialog open={open} fullWidth>
            <DialogTitle>Add project</DialogTitle>

            <DialogContent>
                <TextField
                    variant="standard"
                    margin="dense"
                    id="name"
                    label={<>Project name<span style={{ color: "red" }}>*</span></>}
                    type="text"
                    value={project.name}
                    fullWidth
                    onChange={onChangeName}
                />

                <FormControl variant="standard" fullWidth sx={{ mt: '8px', mb: '4px' }}>
                    <InputLabel id="departmentId">Department<span style={{ color: "red" }}>*</span></InputLabel>
                    <Select
                        margin="dense"
                        id="departmentId"
                        type="text"
                        value={project.departmentId}
                        fullWidth
                        onChange={onChangeDepartment}
                    >
                        {departmentList.map((department: Department) => <MenuItem key={department.departmentId} value={department.departmentId}>{department.departmentId} - {department.description}</MenuItem>)}
                    </Select>
                </FormControl>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClickCancelDialog}>Cancel</Button>
                <Button variant="contained" disabled={!canCreate} onClick={onCreate}>Save</Button>
            </DialogActions>
        </Dialog>
    );
}