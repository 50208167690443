import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { useContext, useMemo, useState } from "react";
import { ProjectSettingsContext } from "../../contexts/ProjectSettingsContext";
import Project from "../../models/Project";
import { useUpdateProject } from "../../queries/ProjectSettingsQueries";
import WarningText from "../common/WarningText";

interface EditStationDialogProps {
    readonly stationId: string,
    readonly setStationIdToEdit: (stationId: string) => void,
}

export default function EditStationDialog(props: EditStationDialogProps) {
    const { stationId, setStationIdToEdit } = props;

    const { projectList } = useContext(ProjectSettingsContext);

    const { mutate: editProject } = useUpdateProject();

    const currentProject = useMemo(() => {
        if (!stationId){ return undefined; }
        const project = projectList
            .find(project => project.lines
                .some(l => l.stations
                    .some(station => station.id === stationId)
                )
            );
        return project;
    }, [projectList, stationId]);

    const currentLine = useMemo(() => {
        if (!currentProject){ return undefined; }
        return currentProject.lines.find(l => l.stations.some(s => s.id === stationId));
    }, [currentProject]);

    const currentStation = useMemo(() => {
        if (!currentLine){ return undefined; }
        for (const station of currentLine.stations){
            if (station.id === stationId){ return station; }
        }
        return undefined;
    }, [currentLine, stationId]);

    const [stationName, setStationName] = useState(currentStation?.name ?? "");
    function onChangeStationName(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>){
        setStationName(e.target.value);
    }

    const handleClickCancelDialog = () => {
        setStationIdToEdit("");
        setStationName(currentStation?.name ?? "");
    };

    function onEditStation(){
        if (!currentProject){ return; }
        if (!currentLine){ return; }
        if (!currentStation || !stationName){ return; }
        const payload: Project = {
            ...currentProject,
            lines: currentProject.lines
                .map(l => l.stations.some(s => s.id === stationId)
                    ? {
                        ...l,
                        stations: l.stations.map(s => s.id === stationId ? {...s, name: stationName } : s)
                    }
                    : l 
                ),
            changeTracker: {
                oldDepartmentId: currentProject.departmentId,
                oldProjectName: currentProject.name,
                oldLineName: currentLine.name,
                oldStationName: currentStation.name,
                newNameValue: stationName
            }
        }
        editProject(payload, { onSuccess: handleClickCancelDialog });
    }

    function onDeleteStation(){
        if (!currentProject || !currentLine || !currentStation){ return; }
        const shouldDelete = window.confirm(`Are you sure that you want to delete the station?`);
        if (!shouldDelete) { return; }
        const payload: Project = {
            ...currentProject,
            lines: currentProject.lines
                .map(l => l.stations.some(s => s.id === stationId)
                    ? {...l, stations: l.stations.filter(s => s.id !== stationId)}
                    : l 
                ),
            changeTracker: {
                oldDepartmentId: currentProject.departmentId,
                oldProjectName: currentProject.name,
                oldLineName: currentLine.name,
                oldStationName: currentStation.name,
            }
        }

        editProject(payload, { onSuccess: handleClickCancelDialog });
    }

    return (
        <Dialog open={Boolean(stationId)} fullWidth>
            <DialogTitle>Edit station</DialogTitle>

            <DialogContent>
                <TextField
                    variant="standard"
                    margin="dense"
                    id="name"
                    label={<>Station name<span style={{ color: "red" }}>*</span></>}
                    type="text"
                    value={stationName}
                    fullWidth
                    onChange={onChangeStationName}
                />

                <Box mt={2}>
                    <WarningText text="The station name will be automatically updated in the Headcount Summary table." mb={1} />
                    <WarningText text="The station name will be automatically updated for all records in the current month in the Daily Workload table." mb={1} />
                    <WarningText text="Deleting this station will result in removing it from all employees in the Headcount Summary table. Please assign a new station to the affected employees." />
                </Box>
            </DialogContent>

            <DialogActions sx={{ justifyContent: "space-between", mx: 1.5 }}>
                <Button onClick={onDeleteStation} color="error" disabled={!currentStation}>Delete</Button>

                <Box>
                    <Button onClick={handleClickCancelDialog}>Cancel</Button>
                    <Button onClick={onEditStation} variant="contained" disabled={!currentStation}>Save</Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
}