import { Info } from '@mui/icons-material'
import { Alert, Box } from '@mui/material'


interface WarningTextProps {
    readonly text: string,
    readonly mb?: number
}

export default function WarningText({ text, mb }: WarningTextProps) {
  return (
    <Box mb={mb}>
      <Alert icon={<Info sx={{ fontSize: 18, mt: 0.3, color: "info.main" }} />} severity='info'>
        {text}
      </Alert>
    </Box>
  )
}
