import { Box, Button, Chip, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import { useContext, useMemo, useState } from "react";
import { LayoutContext } from "wcz-layout";
import ProjectSettingsCard from "../components/settings/ProjectSettingsCard";
import { useGetProjects } from "../queries/ProjectSettingsQueries";
import Project from "../models/Project";
import { Add, Search } from "@mui/icons-material";
import AddProjectDialog from "../components/settings/AddProjectDialog";


export default function ProjectSettings() {
    const { user } = useContext(LayoutContext);
    const { data: projectList = [] } = useGetProjects({ enabled: !!user.name });

    const [searched, setSearched] = useState("");
    function onSetSearched(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>){
        setSearched(e.target.value);
    }

    const filteredProjectList = useMemo(() => {
        if (!projectList.length){ return []; }
        if (!searched){ return projectList; }
        return projectList.filter(project => {
            const searchedText = searched.toUpperCase();
            return project.departmentId.toUpperCase().includes(searchedText) || project.name.toUpperCase().includes(searchedText);
        });
    }, [projectList, searched]);

    const [showAddProjectDialog, setShowAddProjectDialog] = useState(false);
    function openAddProjectDialog(){ setShowAddProjectDialog(true); }

    return (
        <Box>
            <Stack direction="row" justifyContent="space-between" alignItems="flex-end" mb={0.5} px="20px" pt="25px">
                <Typography variant="h5" color="text.secondary" sx={{ mb: 0 }} gutterBottom component="div">Project Settings</Typography>

                <Stack direction="row" justifyContent="space-between" alignItems="flex-end" gap={3}>
                    <TextField
                        value={searched}
                        onChange={onSetSearched}
                        placeholder="Search"
                        variant="standard"
                        InputProps={{ startAdornment: <InputAdornment position="start"><Search /></InputAdornment> }}
                    />
                    <Chip label="Add Project" icon={<Add />} onClick={openAddProjectDialog} />
                </Stack>

            </Stack>
            {
                filteredProjectList?.map((project: Project) => <ProjectSettingsCard key={project.id} project={project} />)
            }

            <AddProjectDialog open={showAddProjectDialog} setOpen={setShowAddProjectDialog} />
        </Box>
      );
}